/* src/index.css (ou o arquivo CSS global que você estiver usando) */
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  /* Evita rolagem horizontal */
}

/* Adicionalmente, ajuste o padding-top do conteúdo para acomodar a altura do AppBar fixo */
#root {
  padding-top: 64px;
  /* Altura padrão do AppBar do Material-UI */
}