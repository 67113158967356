body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: white;
    text-align: center;
    padding: 1rem;
    z-index: 1000;
    /* Garante que o header esteja sempre acima dos outros elementos */
}

main {
    margin-top: 70px;
    /* Ajuste esta margem de acordo com a altura do seu header */
    padding: 1rem;
}